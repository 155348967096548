import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import axios from 'axios';
import './Notice.css';
import { Message } from './Message';
// import { Proxy } from '../config';
// import NoticeDelete from './NoticeDelete';

const Notice = () => {
	const [posts, setPosts] = useState([]);
	const [message, setMessage] = useState('');

	useEffect(() => {
		getNotice();
	}, []);

	const getNotice = async () => {
		try {
			await axios.get(`/api/notice`).then((res) => {
				if (res.status === 200) {
					let data = res.data;
					setPosts(data);
				}
			});
		} catch (err) {
			if (err.response.status === 500) {
				setMessage('There is server error(front onclickEdit)');
			} else {
				setMessage(err.response.data);
			}
		}
	};

	return (
		<div className="notice">
			<p className="h1">NOTICE</p>
			<br></br>
			<div className="noticelist">
				{posts !== ''
					? posts.map((noticeData, i) => (
							<ListGroup key={noticeData.id}>
								<ListGroup.Item>{noticeData.noticeDetail}</ListGroup.Item>
							</ListGroup>
					  ))
					: null}
			</div>
			{message !== '' ? <Message /> : null}
		</div>
	);
};

export default Notice;
