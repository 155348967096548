import React, { useState } from 'react';
// import { ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import { token } from '../components/Session';
import './Notice.css';

// import { Proxy } from '../config';
// import NoticeDelete from './NoticeDelete';

const NoticeInput = () => {
	const [noticeDetail, setNoticeDetail] = useState('');

    const handleValueChange = (e) => {
		setNoticeDetail(e.target.value);
	};

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (noticeDetail === ''){
            alert('Input detail')
        }else{
            addNotice()
        }
    }

    const addNotice = async () => {
        const formData = new FormData()
        formData.append('noticeDetail', noticeDetail);
		const url = '/api/addnotice';
        try{
            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `bearer ${token}`,
                },
            })
            .then((res)=>{
                const response = res.data;
                if (response.message.msgError === false) {
                    window.alert("The report was saved.")
                    console.log(res)
                }
            })
        } catch (err) {
            console.log(err);
            window.alert('Error: f-20')
        }}


	return (
		<div className="noticeinput">
				<Form onSubmit={handleFormSubmit}>
					<Button type="submit">Add a Notice</Button>
					<p></p>
					<Form.Control
						size="lg"
						type="text"
						placeholder="Input"
						name="noticeDetail"
						value={noticeDetail}
						onChange={handleValueChange}
					/>
				</Form>
			</div>
	);
};

export default NoticeInput;
