import React from 'react';
import PropTypes from 'prop-types';

export const Message = ({ msg }) => {
	return (
		<div className="alert alert-danger" role="alert" style={{ margin: 10 }}>
			{msg}
		</div>
	);
};

export const SuccessMessage = ({ msg }) => {
	return (
		<div className="alert alert-success" role="alert" style={{ margin: 10 }}>
			{msg}
		</div>
	);
};

Message.propTypes = {
	msg: PropTypes.string.isRequired,
};
