import React, { useState, useEffect, memo } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { CloseButton, SubmitButton, ClearButton } from '../Buttons';
import { token } from '../Session';
import { Loading } from '../Loading';

Modal.setAppElement('#root');
const modalText = {
	color: 'darkslateblue',
	marginBottom: 40,
};
const input = {
	width: 320,
};

export const TotalAddScFileModal = memo((props) => {
	const carrierId = props.id;
	const carrierIdUpperCase = carrierId.toUpperCase();
	const [modalOpen, setModalOpen] = useState(false);
	const [data, setData] = useState([]);
	const [TotalScNum, setTotalScNum] = useState('');
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('Choose file');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getTotalScs = async () => {
			const config = {
				headers: {
					Authorization: `bearer ${token}`,
				},
			};
			try {
				// await axios.get(`/api/${carrierId}dropdown`, config).then((res) => {
				// 	if (res.data !== '') {
				// 		setData(res.data.rows);
				// 	}
				// });
				await axios.get(`/api/totaldropdown/${carrierIdUpperCase}`, config).then((res) => {
					if (res.data !== '') {
						setData(res.data.rows);
					}
				});
			} catch (err) {
				console.log(err);
				console.error(err);
			}
		};
		getTotalScs();
	}, [carrierIdUpperCase]);

	const TotalScList = data.map((TotalSc) => (
		<option key={TotalSc.id} value={TotalSc.sc_number}>
			{TotalSc.sc_number}
			{/* Effective: {TotalSc.effective}
			Ending: {TotalSc.ending} */}
		</option>
	));

	const onChange = (e) => {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name);
	};

	const handleClear = () => {
		setFileName('');
	};

	const sendUpload = (e) => {
		e.preventDefault();
		if (TotalScNum === '' || file === '') {
			window.alert('Contents must be selected');
		} else {
			onSubmit();
			setLoading(true);
			// console.log('faile');
		}
	};

	// console.log('totalscnum : ', TotalScNum);

	const onSubmit = async () => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('TotalScNum', TotalScNum);
		formData.append('carrierName', carrierIdUpperCase);
		try {
			const res = await axios.post(`/api/scfileupload`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `bearer ${token}`,
				},
			});
			// const { fileName, filePath } = res.data;
			// console.log('res : ', res);
			// console.log('fileName : ', fileName);
			// console.log('filePath : ', filePath);
			if (res.status === 200) {
				// console.log(res.status);
				// console.log(res);
				window.alert(res.data.message.msgBody);
				setLoading(false);
				window.location.reload();
				setModalOpen(false);
			}
			// console.log('300 :', res);

			// setUploadedFile({ fileName, filePath });
			// setFile('');
			// setFileName('');
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div>
			<button type="button" className="btn btn-outline-primary btn-md" onClick={() => setModalOpen(true)}>
				Add SC
			</button>
			<Modal
				isOpen={modalOpen}
				onRequestClose={() => setModalOpen(false)}
				style={{
					overlay: {
						backgroundColor: 'ghostwhite',
					},
					content: {
						color: 'darkblue',
						backgroundColor: 'beige',
					},
				}}
			>
				<div className="container">
					<h2 style={modalText}>Add {carrierId.toUpperCase()} service contract</h2>
					{loading ? <Loading /> : null}
					{data.length > 0 ? (
						<form>
							<div className="form-row align-items-center">
								<div className="col-auto">
									<select
										className="form-control mb-2"
										name="carrier"
										value={TotalScNum}
										style={input}
										onChange={(e) => setTotalScNum(e.target.value)}
									>
										<option value="select">Select S/C</option>
										{TotalScList}
									</select>
								</div>
							</div>
						</form>
					) : (
						<div>
							<h5>Please login again or add SC first</h5>
						</div>
					)}

					<form>
						<div className="custom-file" style={{ width: 395 }}>
							<input type="file" className="custom-file-input" id="customFile" onChange={onChange} />
							<label className="custom-file-label" htmlFor="customFile">
								{fileName}
							</label>
						</div>
						<SubmitButton title="Upload" onClick={sendUpload} />
						<ClearButton onClick={handleClear} />
						{/* <Progress percentage={upLoadPercentage} /> */}
					</form>
					<div style={{ marginTop: 150 }}>
						<CloseButton onClick={() => setModalOpen(false)} />
					</div>
				</div>
			</Modal>
		</div>
	);
});
