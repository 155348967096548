import React, { memo } from 'react';
import { Navbar, NavDropdown, Nav, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import LogoV3 from '../assets/bnxLogo1.png';
import CryptoJS from 'crypto-js';
import { SecretCode } from '../config';

const NavBar = memo((props) => {
	// const hashedData = window.sessionStorage.getItem('SessionData') ? window.sessionStorage.getItem('SessionData') : null;
	const hashedData = window.sessionStorage.getItem('SessionData') ? window.sessionStorage.getItem('SessionData') : null;
	const bytes = hashedData ? CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`) : null;
	const decryptData = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : null;
	const auth = decryptData ? decryptData.results : null;

	const logOut = () => {
		window.sessionStorage.removeItem('SessionData');
		props.history.push('/');
	};

	return (
		<div>
			<Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#e6f9ff' }} variant="light">
				{/* <Navbar.Brand href="home">BNX SYSTEM</Navbar.Brand> */}
				<Navbar.Brand href="/home">
					<Image src={LogoV3} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto">
						{/* <Nav.Link href="agentlist">Agent List</Nav.Link> */}
						<NavDropdown title="Meeting List" id="collasible-nav-dropdown">
							<NavDropdown.Item href="/agentlist">Meeting List</NavDropdown.Item>
							<NavDropdown.Item href="/meetingreport">Input Report</NavDropdown.Item>
							{/* <NavDropdown.Item href="changepassword">Change Password</NavDropdown.Item> */}
							{/* <NavDropdown.Divider />
							<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
						</NavDropdown>
						{auth.roleCode !== 'ADM' && auth.roleCode !== 'ADM2' ? null : (
							<NavDropdown title="Service Contract" id="collasible-nav-dropdown">
								<NavDropdown.Item href="/servicecontractimport">S/C List - Import</NavDropdown.Item>
								<NavDropdown.Item href="/servicecontract">S/C List - Export</NavDropdown.Item>
								<NavDropdown.Item href="/scfiles">S/C Files by Carriers</NavDropdown.Item>
							</NavDropdown>
						)}
					</Nav>
					<Nav>
						<Nav.Link href="/profile">Profile</Nav.Link>
						{auth.roleCode !== 'ADM' ? null : (
							<Nav.Link eventKey={2} href="/userlist">
								User List
							</Nav.Link>
						)}
						{auth.roleCode !== 'ADM' ? null : (
							<Nav.Link eventKey={2} href="/register">
								Register
							</Nav.Link>
						)}
						<Nav.Link onClick={logOut} style={{ color: 'red' }}>
							Logout
						</Nav.Link>
						{/* <Nav.Link href="/">{auth.nickName}</Nav.Link> */}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<div style={{ textAlign: 'right', marginRight: 20 }}>
				Hello!!! <span style={{ color: 'darkblue' }}>{auth.name}</span>
			</div>
		</div>
	);
});

export default withRouter(NavBar);
