import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';

const buttonStyle = {
	margin: 4,
};

export const SubmitButton = ({ title, onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-primary btn-block" onClick={onClick}>
				{title}
			</button>
		</div>
	);
};

export const AplButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-primary btn-block" onClick={onClick}>
				APL
			</button>
		</div>
	);
};

export const HmmButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-secondary btn-block" onClick={onClick}>
				HMM
			</button>
		</div>
	);
};

export const SmButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-success btn-block" onClick={onClick}>
				SM LINE
			</button>
		</div>
	);
};

export const OneButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-danger btn-block" onClick={onClick}>
				ONE
			</button>
		</div>
	);
};

export const CoscoButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-warning btn-block" onClick={onClick}>
				COSCO
			</button>
		</div>
	);
};

export const CmaButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-info btn-block" onClick={onClick}>
				CMA-CGM
			</button>
		</div>
	);
};

export const OoclButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-primary btn-block" onClick={onClick}>
				OOCL
			</button>
		</div>
	);
};

export const YangMingButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-dark btn-block" onClick={onClick}>
				YANG MIMG
			</button>
		</div>
	);
};

export const LoginButton = ({ title, onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-primary btn-block" onClick={onClick}>
				{title}
			</button>
		</div>
	);
};

export const ClearButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-secondary btn-block" onClick={onClick}>
				Clear
			</button>
		</div>
	);
};

export const CloseButton = ({ onClick }) => {
	return (
		<div style={{ margin: 10 }}>
			<button type="button" className="btn btn-outline-secondary btn-block" onClick={onClick}>
				Close
			</button>
		</div>
	);
};

export const SmallSubmitButton = ({ onClick }) => {
	return (
		<Button variant="outline-primary" onClick={onClick} style={buttonStyle}>
			Submit
		</Button>
	);
};

export const SmallClearButton = ({ onClick }) => {
	return (
		<Button variant="outline-secondary" onClick={onClick} style={buttonStyle}>
			Clear
		</Button>
	);
};

export const SmallCloseButton = ({ onClick }) => {
	return (
		<Button variant="outline-secondary" onClick={onClick} style={buttonStyle}>
			Close
		</Button>
	);
};

export const SmallUploadButton = ({ onClick }) => {
	return (
		<Button variant="outline-info" onClick={onClick} style={buttonStyle}>
			Upload
		</Button>
	);
};

export const SearchButton = ({ onClick }) => {
	return (
		<Button variant="outline-primary" onClick={onClick} style={buttonStyle}>
			Search
		</Button>
	);
};

export const SaveButton = ({ onClick }) => {
	return (
		<Button variant="outline-primary" onClick={onClick} style={buttonStyle}>
			Save
		</Button>
	);
};

export const NewButton = ({ onClick }) => {
	return (
		<Button variant="outline-primary" onClick={onClick} style={buttonStyle}>
			New
		</Button>
	);
};

export const CopyButton = ({ onClick }) => {
	return (
		<Button variant="outline-primary" onClick={onClick} style={buttonStyle}>
			Copy
		</Button>
	);
};

export const EditButton = ({ onClick }) => {
	return (
		<Button variant="outline-info" onClick={onClick} style={buttonStyle}>
			Edit
		</Button>
	);
};

export const SmallAddButton = ({ onClick }) => {
	return (
		<Button variant="outline-primary" onClick={onClick} style={buttonStyle}>
			Add
		</Button>
	);
};

export const ExcelButton = ({ onClick }) => {
	return (
		<Button variant="outline-primary" onClick={onClick} style={buttonStyle}>
			Excel
		</Button>
	);
};

export const DeleteButton = ({ onClick }) => {
	return (
		<Button variant="outline-danger" onClick={onClick} style={buttonStyle}>
			Delete
		</Button>
	);
};

export const DelButton = ({ onClick }) => {
	return (
		<Button variant="outline-danger" onClick={onClick} style={buttonStyle}>
			Del
		</Button>
	);
};

export const GoBackButton = ({ onClick }) => {
	return (
		<Button variant="outline-info" onClick={onClick} style={buttonStyle}>
			Go Back
		</Button>
	);
};

export const GoBackButton2 = ({ onClick }) => {
	return (
		<Button variant="outline-info" onClick={onClick}>
			Go Back
		</Button>
	);
};

export const SendEmailButton = ({ onClick }) => {
	return (
		<Button variant="success" onClick={onClick}>
			Send email
		</Button>
	);
};

export const DropdownButton = ({ onClick1, onClick2, onClick3, menuTitle, title1, title2, title3 }) => {
	return (
		<Dropdown>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				{menuTitle}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item onClick={onClick1}>{title1}</Dropdown.Item>
				<Dropdown.Item onClick={onClick2}>{title2}</Dropdown.Item>
				<Dropdown.Item onClick={onClick3}>{title3}</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};
