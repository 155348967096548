import CryptoJS from 'crypto-js';
import { SecretCode } from '../config';

// const hashedData = window.sessionStorage.getItem('SessionData') ? window.sessionStorage.getItem('SessionData') : null;
// const bytes = hashedData ? CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`) : null;
// const decryptData = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : null;
// export const auth = decryptData ? decryptData.results : null;
// export const token = auth ? decryptData.token : null;
// setTimeout(() => {
// 	window.sessionStorage.removeItem('SessionData');
// }, 300000);

const hashedData = window.sessionStorage.getItem('SessionData') ? window.sessionStorage.getItem('SessionData') : null;
const bytes = hashedData ? CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`) : null;
const decryptData = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : null;
export const auth = decryptData ? decryptData.results : null;
export const token = auth ? decryptData.token : null;
setTimeout(() => {
	window.sessionStorage.removeItem('SessionData');
}, 1800000);

// export const hashedEmail = CryptoJS.AES.encrypt(JSON.stringify(email), 'ddd').toString();
// export const hashedName = CryptoJS.AES.encrypt(JSON.stringify(name), 'ddd').toString();
// export const hashedRole = CryptoJS.AES.encrypt(JSON.stringify(role), 'ddd').toString();

// const data = res.data;
// const hashedData = CryptoJS.AES.encrypt(JSON.stringify(data), `${SecretCode}`).toString();
// console.log('hashedData : ', hashedData);
// const bytes = CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`);
// const decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// console.log('decrypted data : ,', decryptData);
// window.sessionStorage.setItem('data', hashedData);
// props.history.push('/home');

// const aa = window.sessionStorage.getItem('data');
// const bb = CryptoJS.AES.decrypt(aa.toString(), `${SecretCode}`);
// const cc = JSON.parse(bb.toString(CryptoJS.enc.Utf8));
// console.log('getItem : ', cc);

// auth = {
//     email:
//     id:
//     isDeleted:
//     name:
//     roleCode
// }

// RAYNAVBAR는 SESSION에서 불러오지 않고 직접 SESSION에 접근을 함.
