import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import './AplSc.css';
import { token, auth } from '../../components/Session';
import { TotalAddScFileModal } from '../../components/Modals/TotalAddScFileModal';
import { s3Url } from '../../config';
import { GoBackButton2, DelButton } from '../../components/Buttons';

const titleStyles = {
	textAlign: 'center',
	color: 'white',
};

const titleDateStyles = {
	textAlign: 'center',
	color: 'white',
	width: 100,
};

const tdStyles = {
	textAlign: 'center',
	paddingTop: 10,
	// paddingRight: 20,
};

const deleteStyles = {
	textAlign: 'center',
	paddingTop: 10,
	// paddingRight: 20,
	color: 'white',
	width: 70,
};

export const TotalScFiles = (props) => {
	const { id } = useParams();
	// console.log(id);
	const [totalScFileList, setTotalScFileList] = useState([]);
	const [backgroundColor, setBackgroundColor] = useState('brown');

	useEffect(() => {
		const getScFiles = async () => {
			let config = {
				headers: {
					Authorization: `bearer ${token}`,
				},
			};
			try {
				// await axios.get(`/api/${id}servicecontractfiles`, config).then((res) => {
				await axios.get(`/api/totalservicecontractfiles/${id}`, config).then((res) => {
					let data = res.data.rows;
					console.log(data);
					// console.log('data[0].uploaded_file : ', data[0].uploaded_file);
					// const backFileName = data.length === 0 ? null : data[0].uploaded_file;
					// const trimmedFileName = backFileName !== null ? backFileName.substring(25, backFileName.length) : null;
					// console.log('data : ', data);
					setTotalScFileList(data);
					// setFileName(trimmedFileName);
				});
			} catch (err) {
				window.alert(err);
				console.log(err);
			}
		};
		getScFiles();
		const getColor = () => {
			if (id === 'apl') {
				setBackgroundColor('dodgerblue');
			}
			if (id === 'hmm') {
				setBackgroundColor('darkgray');
			}
			if (id === 'sm') {
				setBackgroundColor('green');
			}
			if (id === 'cosco') {
				setBackgroundColor('orange');
			}
			if (id === 'cma') {
				setBackgroundColor('mediumturquoise');
			}
			if (id === 'yangming') {
				setBackgroundColor('black');
			}
			if (id === 'oocl') {
				setBackgroundColor('dodgerblue');
			}
		};
		getColor();
	}, [id]);

	const handleDownload = (e, totalScFile) => {
		// console.log('totalScFile : ', totalScFile);
		window.open(`${s3Url}${totalScFile.uploaded_file}`);
	};

	const deleteSc = async (e, id) => {
		let config = {
			headers: {
				Authorization: `bearer ${token}`,
			},
		};
		try {
			await axios.delete(`/api/deletesc/${id}`, config).then((res) => {
				if (res.data.message.msgError === false) {
					window.alert(res.data.message.msgBody);
					// props.history.push('/scfiles');
					// console.log(res.data);
					window.location.reload();
				} else {
					window.alert(res.data.message.msgBody);
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<NavBar />
			<div className="container">
				<h1 style={{ textAlign: 'center', marginTop: 30, marginBottom: 20 }}>{id.toUpperCase()} Service Contract Files</h1>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
					{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? <TotalAddScFileModal id={id} /> : null}
					<div style={{ marginLeft: 5 }}>
						<GoBackButton2 onClick={() => props.history.push('/scfiles')} />
					</div>
				</div>
				<table className="table table-sm table-hover table-bordered" style={{ marginTop: 5 }}>
					<thead>
						<tr style={{ backgroundColor: `${backgroundColor}` }}>
							<th scope="col" style={titleDateStyles}>
								Date
							</th>
							<th scope="col" style={titleStyles}>
								Carrier
							</th>
							<th scope="col" style={titleStyles}>
								Dept.
							</th>
							<th scope="col" style={titleStyles}>
								S/C #
							</th>
							<th scope="col" style={titleDateStyles}>
								Effective
							</th>
							<th scope="col" style={titleDateStyles}>
								Ending
							</th>
							<th scope="col" style={titleStyles}>
								Remark
							</th>

							<th scope="col" style={titleStyles}>
								File Name
							</th>
							{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? (
								<th scope="col" style={deleteStyles}>
									Delete
								</th>
							) : null}
						</tr>
					</thead>
					<tbody>
						{totalScFileList.length > 0
							? totalScFileList.map((totalScFile) => (
									<tr key={totalScFile.id}>
										<td style={{ paddingLeft: 10, paddingTop: 10 }}>
											{moment(totalScFile.create_dt).format('M/DD/YYYY')}
										</td>
										<td style={{ color: 'blue', fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}>
											{totalScFile.carrier}
										</td>
										<td style={{ color: 'blue', fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}>
											{totalScFile.import_export}
										</td>
										<td style={{ paddingLeft: 10, paddingTop: 10 }}>{totalScFile.sc_number}</td>
										<td style={tdStyles}>{totalScFile.effective}</td>
										<td style={tdStyles}>{totalScFile.ending}</td>
										<td style={{ paddingLeft: 10, paddingTop: 10 }}>{totalScFile.remark}</td>
										<td
											style={{ paddingTop: 10, color: 'purple', fontWeight: 'bold' }}
											onClick={(e) => handleDownload(e, totalScFile)}
										>
											{totalScFile.file_name}
										</td>
										{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? (
											<td style={{ textAlign: 'center', padding: 5 }}>
												<DelButton
													variant="outline-success"
													onClick={(e) => {
														window.confirm('Are you sure???') && deleteSc(e, totalScFile.id);
													}}
												/>
											</td>
										) : null}
									</tr>
							  ))
							: null}
					</tbody>
				</table>
			</div>
		</>
	);
};
