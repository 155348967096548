import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	// const auth = JSON.parse(window.sessionStorage.getItem('user'));
	// console.log('PROTECTEDROUTE AUTH : ', auth);
	// const hashedData = window.sessionStorage.getItem('SessionData') ? window.sessionStorage.getItem('SessionData') : null;
	const hashedData = window.sessionStorage.getItem('SessionData') ? window.sessionStorage.getItem('SessionData') : null;
	// const bytes = CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`);
	// const decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	// const auth = decryptData.results;

	// {hashedData !=='' ? (
	// const bytes = CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`)
	// const decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	// const auth = decryptData.results): null}

	// console.log('protectRoute hashedData : ', hashedData);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (hashedData !== null) {
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: '/nomatach',
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
			}}
		/>
	);
};
