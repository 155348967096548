import React, { useState, memo, useEffect } from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import { Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import './MeetingReport.css';
import NavBar from '../components/NavBar';
import { EditButton, GoBackButton, DeleteButton } from '../components/Buttons';
import { auth, token } from '../components/Session';
import { Message } from '../components/Message';
import { s3Url } from '../config';

const input1 = {
	width: 390,
};

const MeetingReportEdit = (props) => {
	const { id } = useParams();

	const [date, setDate] = useState('');
	const [name, setName] = useState('');
	const [aa, setAa] = useState('');
	const [cod, setCod] = useState('');
	const [city, setCity] = useState('');
	const [county, setCounty] = useState('');
	const [agentName, setAgentName] = useState('');
	const [visitors, setVisitors] = useState('');
	const [agentAddress, setAgentAddress] = useState('');
	const [meetingWith, setMeetingWith] = useState('');
	const [specialRequest, setSpecialRequest] = useState('');
	const [detail, setDetail] = useState('');
	const [file, setFile] = useState('');
	const [file2, setFile2] = useState('');
	const [file3, setFile3] = useState('');
	const [fileAddress, setFileAddress] = useState('');
	const [fileAddress2, setFileAddress2] = useState('');
	const [fileAddress3, setFileAddress3] = useState('');
	const [message, setMessage] = useState('');

	useEffect(() => {
		const getClickedIdData = async () => {
			try {
				await axios.get(`/api/clickedagent/${id}`).then((res) => {
					let agent = res.data;
					setDate(agent[0].date);
					setName(agent[0].name);
					setAa(agent[0].AA);
					setCod(agent[0].COD);
					setCity(agent[0].city);
					setCounty(agent[0].county);
					setAgentName(agent[0].agentName);
					setVisitors(agent[0].visitors);
					setAgentAddress(agent[0].agentAddress);
					setMeetingWith(agent[0].meetingWith);
					setSpecialRequest(agent[0].specialRequest);
					setDetail(agent[0].detail);
					setFile(agent[0].bizCard);
					setFile2(agent[0].bizCard2);
					setFile3(agent[0].bizCard3);
					setFileAddress(agent[0].bizCard);
					setFileAddress2(agent[0].bizCard2);
					setFileAddress3(agent[0].bizCard3);
				});
			} catch (err) {
				console.log(err);
			}
		};
		getClickedIdData();
	}, [id]);

	// const handleClear = () => {
	// 	window.location.reload();
	// };

	const editAgent = async () => {
		const formData = new FormData();
		formData.append('id', id);
		formData.append('date', date);
		formData.append('writer', name);
		formData.append('aa', aa);
		formData.append('cod', cod);
		formData.append('city', city);
		formData.append('county', county);
		formData.append('agentName', agentName);
		formData.append('visitors', visitors);
		formData.append('agentAddress', agentAddress);
		formData.append('meetingWith', meetingWith);
		formData.append('specialRequest', specialRequest);
		formData.append('detail', detail);

		try {
			await axios
				.put(`/api/clickedagent/${id}`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `bearer ${token}`,
					},
				})
				.then((res) => {
					const bData = res.data;
					console.log('bData : ', bData);
					if (bData.message.msgError === false) {
						// window.alert(bData.message.msgBody);
						props.history.push('/agentlist');
					}
				});
		} catch (err) {
			console.log(err);
			window.alert('error: f-20');
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (agentName === '' || detail === '') {
			setMessage('Agent Name and detail are required');
		} else {
			window.confirm('Are you sure to edit?') && editAgent();
		}
	};

	const handleDelete = (e, id) => {
		window.confirm('Are you sure ?') && deleteAgent(e, id);
	};

	const deleteAgent = async (e, id) => {
		try {
			await axios.delete(`/api/clickedagent/${id}`).then((res) => {
				if (res.data.message.msgError === false) {
					// window.alert(res.data.message.msgBody);
					props.history.push('/agentlist');
				} else {
					window.alert(res.data.message.msgBody);
				}
			});
		} catch (err) {
			setMessage(err);
		}
	};

	return (
		<>
			<NavBar />
			<div>
				<div className="row" style={{ height: '50px' }}>
					<div className="col" style={{ textAlign: 'center', fontSize: '40px' }}>
						Agent Report
					</div>
				</div>
				<div style={{ padding: 30 }}>
					<form>
						<div style={{ marginBottom: 20 }}>
							{auth.nickName === name || auth.roleCode === 'ADM' ? <EditButton onClick={handleFormSubmit} /> : null}
							{/* <SmallClearButton onClick={handleClear} /> */}
							<GoBackButton onClick={() => props.history.push('/agentlist')} />
							{auth.nickName === name || auth.roleCode === 'ADM' ? (
								<DeleteButton onClick={(e) => handleDelete(e, id)} />
							) : null}
						</div>
						<div style={{ alignItem: 'center' }}>
							<div className="form-row align-items-center">
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div>
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Date
											</span>
										</div>
										<input
											type="text"
											name="date"
											value={date}
											onChange={(e) => setDate(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="sample: 20190607"
											readOnly
											style={{ fontColor: 'black' }}
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Writer
											</span>
										</div>
										<input
											type="text"
											name="writer"
											value={name}
											onChange={(e) => setName(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											readOnly
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												A/A
											</span>
										</div>
										<input
											type="text"
											name="aa"
											value={aa}
											onChange={(e) => setAa(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="not required"
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												City
											</span>
										</div>
										<input
											type="text"
											name="city"
											value={city}
											onChange={(e) => setCity(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="SEL? BSN?"
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												County
											</span>
										</div>
										<input
											type="text"
											name="county"
											value={county}
											onChange={(e) => setCounty(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="강남구? 강북구?"
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												COD
											</span>
										</div>
										<input
											type="text"
											name="cod"
											value={cod}
											onChange={(e) => setCod(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="not required"
											style={{ color: 'red' }}
										></input>
									</div>
								</div>
							</div>
							<div className="form-row align-items-center" style={{ marginTop: 2 }}>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Agent Name
											</span>
										</div>
										<TextareaAutosize
											type="text"
											name="agentName"
											value={agentName}
											onChange={(e) => setAgentName(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="sample: Sejung Shipping / 세중해운"
											style={{ color: 'blue', fontWeight: 'bold' }}
											minRows={2}
										></TextareaAutosize>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Visitors
											</span>
										</div>
										<TextareaAutosize
											type="text"
											name="visitors"
											value={visitors}
											onChange={(e) => setVisitors(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											minRows={2}
										></TextareaAutosize>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Address
											</span>
										</div>
										<TextareaAutosize
											type="text"
											name="agentAddress"
											value={agentAddress || ''}
											onChange={(e) => setAgentAddress(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											minRows={2}
										></TextareaAutosize>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Meeting with
											</span>
										</div>
										<TextareaAutosize
											type="text"
											name="meeting with"
											value={meetingWith || ''}
											onChange={(e) => setMeetingWith(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											minRows={2}
										></TextareaAutosize>
									</div>
								</div>
							</div>
							<div>{message ? <Message msg={message} /> : null}</div>
							<div className="input-group">
								<TextareaAutosize
									className="form-control"
									aria-label="With textarea"
									type="text"
									name="detail"
									value={detail}
									onChange={(e) => setDetail(e.target.value)}
									style={{ height: '500px' }}
									placeholder="Meeting detail"
									minRows={10}
									// onKeyDown={handleKeyDown}
								/>
							</div>
							<div className="form-row align-items-center">
								<div className="input-group mb-3" style={{ padding: 5 }}>
									<div className="input-group-prepend">
										<span
											className="input-group-text"
											id="inputGroup-sizing-sm"
											style={{ backgroundColor: 'cyan', color: 'red', fontWeight: 'bold' }}
										>
											Special Request
										</span>
									</div>
									<TextareaAutosize
										type="text"
										className="form-control"
										aria-label="Sizing example input"
										aria-describedby="inputGroup-sizing-sm"
										name="special request"
										value={specialRequest || ''}
										onChange={(e) => setSpecialRequest(e.target.value)}
										style={{ color: 'brown', width: '200px' }}
									/>
								</div>
							</div>
						</div>
						{/* <button type="submit" className="btn btn-primary">Send Email</button> */}
					</form>

					<div className="form-row align-items-center" style={{ marginTop: 2, marginBottom: 150 }}>
						<div className="col-auto">
							{file && fileAddress ? (
								<div>
									{/* <h5>{`${s3Url}${fileAddress}`}</h5> */}
									<Image
										src={`${s3Url}${fileAddress}`}
										className="img-thumbnail"
										alt="Responsive image"
										width="550"
										height="292"
									/>
								</div>
							) : null}

							{/* <Image src={bizCard} className="img-thumbnail" alt="Responsive image" width="550" height="292" /> */}
						</div>
						<div className="col-auto">
							{file2 && fileAddress2 ? (
								<div>
									{/* <h5>{`${s3Url}${fileAddress2}`}</h5> */}
									<Image
										src={`${s3Url}${fileAddress2}`}
										className="img-thumbnail"
										alt="Responsive image"
										width="550"
										height="292"
									/>
								</div>
							) : null}
						</div>
						<div className="col-auto">
							{file3 && fileAddress3 ? (
								<div>
									{/* <h5>{`${s3Url}${fileAddress3}`}</h5> */}
									<Image
										src={`${s3Url}${fileAddress3}`}
										className="img-thumbnail"
										alt="Responsive image"
										width="550"
										height="292"
									/>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(MeetingReportEdit);
