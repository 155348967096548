import React, { useState, memo } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { SubmitButton, ClearButton } from '../components/Buttons';
import { Message } from '../components/Message';
import NavBar from '../components/NavBar';
// import { Proxy } from '../config';

const Register = memo(() => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [name, setName] = useState('');
	const [nickName, setNickName] = useState('');
	const [role, setRole] = useState('');
	const [message, setMessage] = useState('');

	const handleChangeEmail = (e) => {
		setEmail(e.target.value);
	};
	const handleChangePassword = (e) => {
		setPassword(e.target.value);
	};
	const handleChangeConfirmPassword = (e) => {
		setConfirmPassword(e.target.value);
	};
	const handleChangeName = (e) => {
		setName(e.target.value);
	};

	const handleChangeNickName = (e) => {
		setNickName(e.target.value);
	};
	const handleChangeRole = (e) => {
		setRole(e.target.value);
	};

	const handleRegister = async (e) => {
		try {
			await axios
				.post(`/api/users`, {
					email,
					password,
					name,
					nickName,
					role,
				})
				.then((res) => {
					if (res.status === 200) {
						setMessage('Registration is successful(front)');
						setTimeout(() => {
							setEmail('');
							setName('');
							setPassword('');
							setConfirmPassword('');
							setNickName('');
							setRole('');
							setMessage('');
						}, 2000);
					} else {
						setTimeout(() => {
							setMessage(res.data.message);
						}, 3000);
					}
				});
		} catch (err) {
			if (err.response.status === 500) {
				setMessage('Server error(front)');
			} else {
				setMessage(err.response.data.message);
			}
		}
	};

	const handleClear = () => {
		setEmail('');
		setPassword('');
		setConfirmPassword('');
		setName('');
		setNickName('');
		setRole('');
		setMessage('');
	};

	const sendRegister = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Password should be same');
		} else {
			if (email === '' || password === '' || confirmPassword === '' || name === '' || role === '' || nickName === '') {
				setMessage('All fields are required');
			} else if (password.length < 7) {
				setMessage('Over 8 length password are required');
			} else {
				handleRegister();
			}
		}
	};

	return (
		<>
			<NavBar />
			<div style={{ margin: 50 }}>
				<Form>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" value={email} onChange={handleChangeEmail} />
						<Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
					</Form.Group>
					<Form.Group controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Password" value={password} onChange={handleChangePassword} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Confirm Password"
							value={confirmPassword}
							onChange={handleChangeConfirmPassword}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Name</Form.Label>
						<Form.Control type="text" placeholder="Name" value={name} onChange={handleChangeName} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Nick Name</Form.Label>
						<Form.Control type="text" placeholder="Name" value={nickName} onChange={handleChangeNickName} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Role</Form.Label>
						<Form.Control type="text" placeholder="Role" value={role} onChange={handleChangeRole} />
					</Form.Group>
					<SubmitButton title="register" onClick={sendRegister} />
					<ClearButton onClick={handleClear} />
				</Form>
				{message ? <Message msg={message} /> : null}
			</div>
		</>
	);
});

export default withRouter(Register);
