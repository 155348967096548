import React from 'react';
import NavBar from '../components/NavBar';
import {
	AplButton,
	HmmButton,
	OneButton,
	SmButton,
	CoscoButton,
	CmaButton,
	YangMingButton,
	OoclButton,
} from '../components/Buttons';

export const ScFiles = (props) => {
	const onClickTotalScFiles = (e, id) => {
		// console.log(id);
		props.history.push(`/totalscfiles/${id}`);
	};

	return (
		<>
			<NavBar />
			<div className="container">
				<h1 style={{ textAlign: 'center', marginTop: 30, marginBottom: 20 }}>Service Contract Files</h1>
				<div style={{ marginBottom: 20 }}>
					<AplButton onClick={(e) => onClickTotalScFiles(e, 'apl')} />
					<HmmButton onClick={(e) => onClickTotalScFiles(e, 'hmm')} />
					<OneButton onClick={(e) => onClickTotalScFiles(e, 'one')} />
					<SmButton onClick={(e) => onClickTotalScFiles(e, 'sm')} />
					<CoscoButton onClick={(e) => onClickTotalScFiles(e, 'cosco')} />
					<CmaButton onClick={(e) => onClickTotalScFiles(e, 'cma')} />
					<YangMingButton onClick={(e) => onClickTotalScFiles(e, 'yangming')} />
					<OoclButton onClick={(e) => onClickTotalScFiles(e, 'oocl')} />
				</div>
			</div>
		</>
	);
};
