import React, { useState, memo, useCallback } from 'react';
import { Form, Container, Image, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Message } from '../components/Message';
import { SubmitButton, ClearButton } from '../components/Buttons';
import CryptoJS from 'crypto-js';
import { SecretCode } from '../config';
import './Login.css';
import LogoV3 from '../assets/bnxLogo1.png';

const Login = memo((props) => {
	const number = Math.random() * 1000000;
	const sixNumber = Math.round(number);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [random, setRandom] = useState(sixNumber);
	const [checkRandom, setCheckRandom] = useState('');
	const numberCheckRandom = checkRandom ? Number(checkRandom) : null;

	const handleLogin = async () => {
		let data2 = {
			email,
			password,
		};
		try {
			await axios.post(`/api/users/login`, data2).then((res) => {
				// console.log(res)
				if (res.data.success === 0) {
					setEmail('');
					setPassword('');
					setMessage(res.data.data);
				} else {
					const data = res.data;
					const hashedData = CryptoJS.AES.encrypt(JSON.stringify(data), `${SecretCode}`).toString();
					window.sessionStorage.setItem('SessionData', hashedData);
					props.history.push('/home');
				}
			});
		} catch (err) {
			if (err.response !== null) {
				console.log(err.response);
				setMessage('Server error(F). Please contact at ray@bnxlogistics.com');
			} else {
				setMessage(err.response.data.message);
			}
		}
	};

	const handleChange1 = useCallback((e) => {
		e.preventDefault();
		setEmail(e.target.value);
		// console.log(email)
	}, []);

	const handleChange2 = (e) => {
		setPassword(e.target.value);
	};

	const handleChange3 = (e) => {
		setCheckRandom(e.target.value);
	};

	const handleClear = () => {
		setEmail('');
		setPassword('');
		setMessage('');
		setRandom(sixNumber);
	};

	const sendLogin = (e) => {
		e.preventDefault();
		if (email === '' || password === '') {
			setMessage('Email & password are required');
		} else {
			if (random !== numberCheckRandom) {
				setMessage('Security code is incorrect');
			} else {
				handleLogin();
			}
		}
	};

	return (
		<>
			<Container>
				<Image src={LogoV3} style={{ marginTop: 50, marginBottom: 40 }} />
				<h1 style={{ marginBottom: 20, textAlign: 'center' }}>Login</h1>
				<Form method="post">
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control
							type="email"
							placeholder="Enter email"
							value={email}
							onChange={handleChange1}
							// onKeyUp={handleChange1}
						/>
					</Form.Group>
					<Form.Group controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Password" value={password} onChange={handleChange2} />
					</Form.Group>
					{email ? (
						<div>
							<div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
								<h5 style={{ color: 'gray', marginRight: 11, marginTop: 3 }}>Security code : </h5>
								<h3 className="random">{random}</h3>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
								<h5 style={{ color: 'gray', marginRight: 10 }}>Check security : </h5>
								<InputGroup size="sm" className="mb-3" style={{ width: 100 }}>
									<FormControl
										aria-label="Small"
										aria-describedby="inputGroup-sizing-sm"
										value={checkRandom}
										onChange={handleChange3}
									/>
								</InputGroup>
							</div>
						</div>
					) : null}

					<SubmitButton title="Submit" onClick={sendLogin} style={{ width: 100 }} />
					<ClearButton onClick={handleClear} />
				</Form>
				<div>{message ? <Message msg={message} /> : null}</div>
			</Container>
		</>
	);
});

export default withRouter(Login);
