import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { SmallAddButton, CloseButton, SmallClearButton } from '../Buttons';
import { Message } from '../Message';
import { token } from '../Session';

Modal.setAppElement('#root');
const modalText = {
	color: 'darkslateblue',
	marginBottom: 40,
};

const input = {
	width: 320,
};

export const AddSc = (props) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [carrier, setCarrier] = useState('');
	const [dropDownCarriers, setDropDownCarriers] = useState([]);
	const [scNum, setScNum] = useState('');
	const [effective, setEffective] = useState('');
	const [ending, setEnding] = useState('');
	const [remark, setRemark] = useState('');
	const [message, setMessage] = useState('');
	const [importExport, setImportExport] = useState('Import');

	const newEffective = effective ? moment(effective).format('M/D/YYYY') : null;
	const newEnding = ending ? moment(ending).format('M/D/YYYY') : null;

	const importExportOption = [
		{value: 'Import', label: 'Import'},
		{value: 'Export', label: 'Export'}
	]

	const getCarrier = async () => {
		try {
			await axios.get(`/api/getcarrier`).then((res) => {
				setDropDownCarriers(res.data.rows);
			});
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getCarrier();
	}, []);

	const carrierDropdownList = dropDownCarriers.map((dropDownCarrier) => (
		<option key={dropDownCarrier.id} value={dropDownCarrier.carrierName}>
			{dropDownCarrier.carrierName}
		</option>
	));

	const handleClear = () => {
		// setCarrier('');
		setScNum('');
		setEffective('');
		setEnding('');
		setRemark('');
		setMessage('');
		setMessage('Import');
	};

	const handleCheck = () => {
		if (carrier !== '' && scNum !== '' && effective !== '' && ending !== '') {
			window.confirm('Do you want to save?') && addSc();
		} else {
			setMessage('All fields are required, except for remark');
		}
	};

	const addSc = async () => {
		// console.log('thank God : ', carrier, scNum, newEffective, newEnding, remark);
		let config = {
			headers: {
				Authorization: `bearer ${token}`,
			},
		};
		let data = { carrier, scNum, newEffective, newEnding, remark, importExport };
		try {
			await axios.post(`/api/addsc`, data, config).then((res) => {
				if (res.status === 200) {
					setMessage(res.data.message.msgBody);
					console.log(res.data);
					setTimeout(() => {
						setModalOpen(false);
						window.location.reload();
					}, 2000);
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	// console.log('newFormatDate : ', newEffective);
	// console.log('newending : ', ending);
	return (
		<div>
			<SmallAddButton onClick={() => setModalOpen(true)} />
			<Modal
				isOpen={modalOpen}
				onRequestClose={() => setModalOpen(false)}
				style={{
					overlay: {
						backgroundColor: 'ghostwhite',
					},
					content: {
						color: 'darkblue',
						backgroundColor: 'beige',
					},
				}}
			>
				<div className="container">
					<h2 style={modalText}>Add a new service contract</h2>
					<form>
						<div className="col" style={{ marginBottom: 20 }}>
							<div className="form-row align-items-center">
								<div className="col-auto">
									<DatePicker
										className="form-control mb-2"
										selected={effective}
										onChange={(date) => setEffective(date)}
										isClearable
										placeholderText="Effective"
									/>

									<DatePicker
										className="form-control mb-2"
										selected={ending}
										onChange={(date) => setEnding(date)}
										isClearable
										placeholderText="Ending"
										// dateFormat="m/ds/yyyy"
										// customInput={{ width: 200 }}
									/>
								</div>
							</div>
							<div className="form-row align-items-center">
								<div className="col-auto">
									{dropDownCarriers ? (
										<select
											className="form-control mb-2"
											name="carrier"
											value={carrier}
											style={input}
											onChange={(e) => setCarrier(e.target.value)}
										>
											<option value="select">Select a carrier</option>
											{carrierDropdownList}
										</select>
									) : (
										<option>empty</option>
									)}
								</div>
								<div className="col-auto">
									<input
										type="text"
										name="scNum"
										className="form-control mb-2"
										placeholder="S/C #"
										value={scNum}
										onChange={(e) => setScNum(e.target.value)}
										style={input}
									/>
								</div>
								<div className="col-auto">
									<input
										type="text"
										htmlFor="remark"
										className="form-control mb-2"
										placeholder="Remark"
										value={remark}
										onChange={(e) => setRemark(e.target.value)}
										style={input}
									/>
								</div>
							</div>

							<div className="form-row align-items-center">
								<div className="col-auto">
									<select
										className="form-control mb-2"
										name="importExport"
										value={importExport}
										onChange={(e) => setImportExport(e.target.value)}
									>
										{
											importExportOption.map(option => (
												<option key={option.value} value={option.value}>{option.label}</option>
											))
										}
									</select>
								</div>

							</div>

							<div style={{ marginTop: 20 }}>
								<SmallAddButton onClick={handleCheck} />
								<SmallClearButton onClick={handleClear} />
							</div>
						</div>
					</form>
					{message ? <Message msg={message} /> : null}
					<div style={{ marginTop: 150 }}>
						<CloseButton onClick={() => setModalOpen(false)} />
					</div>
				</div>
			</Modal>
		</div>
	);
};
