import React, { useState, memo } from 'react';
import { Form, Container, Image } from 'react-bootstrap';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { SubmitButton, ClearButton } from '../components/Buttons';
import { Message } from '../components/Message';
import { Loading } from '../components/Loading';
import LogoV3 from '../assets/bnxLogo1.png';
// import { Proxy } from '../config';

const RegisterPublic = memo((props) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [name, setName] = useState('');
	// const [nickName, setNickName] = useState('NICKNAME');
	const [role] = useState('MGR');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [securityCheck, setSecurityCheck] = useState('');
	const capitalName = name ? name.toUpperCase() : '';

	const handleChangeEmail = (e) => {
		setEmail(e.target.value);
	};
	const handleChangePassword = (e) => {
		setPassword(e.target.value);
	};
	const handleChangeConfirmPassword = (e) => {
		setConfirmPassword(e.target.value);
	};
	const handleChangeName = (e) => {
		setName(e.target.value);
	};
	const handleChangeCheck = (e) => {
		setSecurityCheck(e.target.value);
	};

	const handleRegister = async (e) => {
		try {
			await axios
				.post(`/api/users`, {
					email,
					password,
					name,
					nickName: name,
					role,
				})
				.then((res) => {
					// console.log('res : ', res);
					if (res.data.success === 0) {
						setLoading(false);
						window.alert(res.data.message);
						setEmail('');
						setName('');
						setPassword('');
						setConfirmPassword('');
						// setNickName('');
						// setRole('');
						setMessage('');
					} else {
						setLoading(false);
						window.alert('Registration was done successfully(F)');
						setEmail('');
						setName('');
						setPassword('');
						setConfirmPassword('');
						// setNickName('');
						// setRole('');
						setMessage('');
						props.history.push('/');
					}
				});
		} catch (err) {
			if (err.response.status === 500) {
				setMessage('Server error(front)');
			} else {
				setMessage(err.response.data.message);
			}
		}
	};

	const handleClear = () => {
		setEmail('');
		setPassword('');
		setConfirmPassword('');
		setName('');
		// setNickName('');
		// setRole('');
		setMessage('');
	};

	const sendRegister = (e) => {
		e.preventDefault();

		if (password !== confirmPassword) {
			setMessage('Password should be same');
		} else {
			if (email === '' || password === '' || confirmPassword === '' || name === '') {
				setMessage('All fields are required');
			}
			if (password.length < 7) {
				setMessage('Over 8 length password are required');
			} else {
				setLoading(true);
				handleRegister();
			}
		}
	};

	return (
		<>
			{securityCheck !== '3107640988' ? (
				<Container>
					<Image src={LogoV3} style={{ marginTop: 50, marginBottom: 40 }} />
					<Form>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Security Code</Form.Label>
							<Form.Control
								type="password"
								placeholder="Input Security Code"
								value={securityCheck}
								onChange={handleChangeCheck}
							/>
						</Form.Group>
					</Form>
				</Container>
			) : (
				<Container>
					<Image src={LogoV3} style={{ marginTop: 50, marginBottom: 40 }} />
					<h1 style={{ marginBottom: 20, textAlign: 'center' }}>Register</h1>
					{loading ? <Loading /> : null}
					<Form>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Email address</Form.Label>
							<Form.Control
								type="email"
								placeholder="Use your BNX email address"
								value={email}
								onChange={handleChangeEmail}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicPassword">
							<Form.Label>Create Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Password (over 8)"
								value={password}
								onChange={handleChangePassword}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Confirm Password"
								value={confirmPassword}
								onChange={handleChangeConfirmPassword}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control type="text" placeholder="Name" value={capitalName} onChange={handleChangeName} />
						</Form.Group>
						<SubmitButton title="register" onClick={sendRegister} />
						<ClearButton onClick={handleClear} />
					</Form>
					{message ? <Message msg={message} /> : null}
				</Container>
			)}
		</>
	);
});

export default withRouter(RegisterPublic);
