import React, { memo, useState } from 'react';
import axios from 'axios';
import { Table, Button, InputGroup, FormControl } from 'react-bootstrap';
import Modal from 'react-modal';
import { Message } from './Message';
import { SmallCloseButton, EditButton, SmallClearButton } from './Buttons';
import { token } from './Session';

Modal.setAppElement('#root');

const UserTable = ({ users }) => {
	const [searchEmail, setSearchEmail] = useState('');
	const [searchName, setSearchName] = useState('');
	const [user, setUser] = useState({});
	const [newPassword, setNewPassword] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [message, setMessage] = useState(false);

	const filteredUser =
		searchEmail !== ''
			? users.filter((user) => user.email.toLowerCase().includes(searchEmail.toLowerCase()))
			: users.filter((user) => user.name.toLowerCase().includes(searchName.toLowerCase()));

	const changePassword = (e, user) => {
		setModalOpen(true);
		setUser(user);
	};

	const sendUpdateUser = async () => {
		let email = user.email;
		let data = {
			email,
			newPassword,
		};
		let config = {
			headers: {
				Authorization: `bearer ${token}`,
			},
		};
		try {
			await axios.patch(`/api/users`, data, config).then((res) => {
				if (res.status === 200) {
					setMessage(res.data.message);
					setTimeout(() => {
						setModalOpen(false);
					}, 2000);
				}
			});
		} catch (err) {
			setMessage(err);
		}
	};

	const validatePassword = () => {
		if (newPassword.length < 7) {
			setMessage('Password should be long over 7');
		} else {
			sendUpdateUser();
		}
	};

	const handleClear = () => {
		setMessage('');
		setNewPassword('');
	};

	return (
		<div style={{ margin: 50 }}>
			<input
				type="text"
				placeholder="Search email"
				value={searchEmail}
				onChange={(e) => setSearchEmail(e.target.value)}
			/>
			<input type="text" placeholder="Search Name" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
			<h3>User List</h3>
			{message ? <Message msg={message} /> : null}
			<Table striped bordered hover size="sm">
				<thead>
					<tr>
						<th>id</th>
						<th>email</th>
						<th>role</th>
						<th>name</th>
						<th>nick name</th>
						<th>change</th>
					</tr>
				</thead>
				<tbody>
					{users.length > 0
						? filteredUser.map((user) => (
								<tr key={user.id}>
									<td>{user.id}</td>
									<td>{user.email}</td>
									<td>{user.roleCode}</td>
									<td>{user.name}</td>
									<td>{user.nickName}</td>
									<td>
										<Button
											variant="outline-success"
											onClick={(e) => {
												changePassword(e, user);
											}}
										>
											click
										</Button>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</Table>

			<Modal
				isOpen={modalOpen}
				onRequestClose={() => setModalOpen(false)}
				style={{
					overlay: {
						backgroundColor: 'grey',
					},
					content: {
						color: 'orange',
					},
				}}
			>
				<h2>Change password</h2>
				<InputGroup size="md" className="mb-3" style={{ width: 300 }}>
					<FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" value={user.email} readOnly />
				</InputGroup>
				<InputGroup size="md" className="mb-3" style={{ width: 300 }}>
					<FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" value={user.name} readOnly />
				</InputGroup>
				<InputGroup size="md" className="mb-3" style={{ width: 300 }}>
					<FormControl
						aria-label="Small"
						aria-describedby="inputGroup-sizing-sm"
						value={newPassword}
						onChange={(e) => {
							setNewPassword(e.target.value);
						}}
					/>
				</InputGroup>
				<EditButton onClick={validatePassword} />
				<SmallClearButton onClick={handleClear} />
				{message ? <Message msg={message} /> : null}
				<div style={{ marginTop: 200 }}>
					<SmallCloseButton onClick={() => setModalOpen(false)} />
				</div>
			</Modal>
		</div>
	);
};

export default memo(UserTable);
