import React, { useState } from 'react';
import axios from 'axios';
import { Container, InputGroup, FormControl } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import { withRouter } from 'react-router-dom';
import { auth, token } from '../components/Session';
import { Message } from '../components/Message';
import { SmallSubmitButton, SmallClearButton } from '../components/Buttons';
// import { Proxy } from '../config';

const Profile = (props) => {
	// const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');

	const sendUpdateUser = async () => {
		// e.preventDefault();
		let email = auth.email;
		let data = {
			email,
			// currentPassword,
			newPassword,
		};
		let config = {
			headers: {
				Authorization: `bearer ${token}`,
			},
		};
		try {
			await axios.patch(`/api/users`, data, config).then((res) => {
				if (res.status === 200) {
					setMessage(res.data.message);
					setTimeout(() => {
						window.sessionStorage.removeItem('SessionData');
						props.history.push('/');
					}, 2000);
				}
			});
		} catch (err) {
			console.log('front err : ', err);
		}
	};

	const checkPassword = () => {
		if (newPassword !== confirmPassword) {
			setMessage('Passwords should be same');
		} else if (newPassword === '' || confirmPassword === '' || newPassword.length < 7) {
			setMessage('Over 8 length password is required');
		} else {
			sendUpdateUser();
		}
	};

	const handleClear = () => {
		setNewPassword('');
		// setCurrentPassword('');
		setConfirmPassword('');
		setMessage('');
	};

	return (
		<>
			<NavBar />
			<Container>
				<div style={{ marginBottom: 50 }}>
					<h2>Profile</h2>
					{auth ? (
						<ul>
							<li>
								<span>ID : </span>
								{auth.email}
							</li>
							<li>
								<span>Name : </span>
								{auth.name}
							</li>
							{/* <li>{auth.roleCode}</li> */}
							<li>
								<span>System Name : </span>
								{auth.nickName}
							</li>
						</ul>
					) : null}
				</div>
				<h2>Change password</h2>
				<InputGroup size="md" className="mb-3" style={{ width: 300 }}>
					<FormControl
						type="password"
						aria-label="Small"
						aria-describedby="inputGroup-sizing-sm"
						placeholder="New password"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
					/>
				</InputGroup>
				<InputGroup size="md" className="mb-3" style={{ width: 300 }}>
					<FormControl
						type="password"
						aria-label="Small"
						aria-describedby="inputGroup-sizing-sm"
						placeholder="Confirm password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
				</InputGroup>
				<SmallSubmitButton onClick={checkPassword} />
				<SmallClearButton onClick={handleClear} />

				<div>{message ? <Message msg={message} /> : null}</div>
			</Container>
		</>
	);
};

export default withRouter(Profile);
