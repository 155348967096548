import React, { useState, memo, useEffect } from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';
// import emailjs from 'emailjs-com';
import { Image } from 'react-bootstrap';
import './MeetingReport.css';
import NavBar from '../components/NavBar';
import { SmallClearButton, SaveButton, GoBackButton } from '../components/Buttons';
import { auth, token } from '../components/Session';
import { Message } from '../components/Message';
import { s3Url } from '../config';
import { Loading } from '../components/Loading';
// import Dropdown from "react-bootstrap/Dropdown";

const input1 = {
	width: 390,
};

const input2 = {
	width: 650,
};

const MeetingReport = (props) => {
	const originalToday = moment().toDate();
	const today = moment(originalToday).format('YYYYMMDD');
	const senderEmail = auth ? auth.email : null;

	const [date, setDate] = useState(today);
	const [name, setName] = useState(auth.nickName);
	const [aa, setAa] = useState('');
	const [cod, setCod] = useState('');
	const [city, setCity] = useState('');
	const [county, setCounty] = useState('');
	const [agentName, setAgentName] = useState('');
	const [visitors, setVisitors] = useState('');
	const [agentAddress, setAgentAddress] = useState('');
	const [meetingWith, setMeetingWith] = useState('');
	const [specialRequest, setSpecialRequest] = useState('');
	const [detail, setDetail] = useState('');
	const [sendEmailButton, setSendEmailButton] = useState(false);
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('upload biz card 1');
	const [file2, setFile2] = useState('');
	const [fileName2, setFileName2] = useState('upload biz card 2');
	const [file3, setFile3] = useState('');
	const [fileName3, setFileName3] = useState('upload biz card 3');
	const [fileAddress, setFileAddress] = useState('');
	const [fileAddress2, setFileAddress2] = useState('');
	const [fileAddress3, setFileAddress3] = useState('');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [suggestionAgents, setSuggestionAgents] = useState([])
	const [serverList, setServerList] = useState([])

	// const agentName = agentName !== '' ? agentName.toUpperCase() : '';

	const handleClear = () => {
		window.location.reload();
	};

	const getList = async () => {
		try {
			await axios.get(`/api/agents`).then((res) => {
				if (res.status === 200) {
					let aa = res.data
					// console.log(aa)
					let i = 0;
					let namesOnly = [];
					let cityOnly = [];
					let countyOnly = [];
					for (i; aa.length; i++){
						let oneName = aa[i].agentName.toUpperCase()
						let oneCity = aa[i].city
						let oneCounty = aa[i].county
						namesOnly.push(oneName)
						cityOnly.push(oneCity)
						countyOnly.push(oneCounty)
						let namesOnly2 = [...new Set(namesOnly)]
						namesOnly2.sort()
						let cityOnly2 = [...new Set(cityOnly)]
						cityOnly2.sort()
						let countyOnly2 = [...new Set(countyOnly)]
						countyOnly2.sort()
						// console.log(countyOnly2)
						setServerList(namesOnly2)
					}
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getList();
	}, []);

	// const serverList = ["ABC","PDSA","ECCS","KOI","ABC","PDSA","ECCS","KOI","ABC","PDSA","ECCS","KOI","ABC","PDSA","ECCS","KOI","ABC","PDSA","ECCS","KOI"]

	const handleSuggestion = (e) => {
		setAgentName(e.target.value.toUpperCase());
		setSuggestionAgents(serverList.filter(
			(agent) => agent.startsWith(agentName)
		))
		// console.log(suggestionAgents)
	}

	const clickSuggestion = (suggestion) => {
		setAgentName(suggestion);
		setSuggestionAgents([])
		// console.log(agentName)
	}
	
	const addAgent = async () => {
		const formData = new FormData();
		formData.append('date', date);
		formData.append('writer', name);
		formData.append('aa', aa);
		formData.append('cod', cod);
		formData.append('city', city);
		formData.append('county', county);
		formData.append('agentName', agentName);
		formData.append('visitors', visitors);
		formData.append('agentAddress', agentAddress);
		formData.append('meetingWith', meetingWith);
		formData.append('specialRequest', specialRequest);
		formData.append('detail', detail);
		formData.append('file', file);
		formData.append('file2', file2);
		formData.append('file3', file3);

		try {
			await axios
				.post(`/api/addagent`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `bearer ${token}`,
					},
				})
				.then((res) => {
					const fileData = res.data;
					if (fileData.message.msgError === false) {
						// console.log('res.data : ', res.data);
						// console.log('fileName, filePath : ', fileData.fileName, fileData.filePath);
						// console.log('fileName2, filePath2, : ', fileData.fileName2, fileData.filePath2);
						// console.log('fileName3, filePath3, : ', fileData.fileName3, fileData.filePath3);
						// console.log('file, : ', file);
						window.alert('The report was saved');
						setFile(fileData.fileName);
						setFile2(fileData.fileName2);
						setFile3(fileData.fileName3);
						setFileName(fileData.fileName);
						setFileName3(fileData.fileName2);
						setFileName3(fileData.fileName3);
						setFileAddress(fileData.filePath);
						setFileAddress2(fileData.filePath2);
						setFileAddress3(fileData.filePath3);
						setSendEmailButton(true);
						setLoading(false);
					}
				});
			// const { fileName, fileName2, fileName3, filePath, filePath2, filePath3 } = res.data;
		} catch (err) {
			console.log(err);
			window.alert('error: f-20');
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (agentName === '' || detail === '') {
			// window.alert('Agent Name and detail are required');
			setMessage('Agent Name and detail are required');
		} else {
			window.confirm('Are you sure to save?') && addAgent();
			setLoading(true);
		}
	};

	const onChange = (e) => {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name);
	};

	const onChange2 = (e) => {
		setFileName2(e.target.files[0].name);
		setFile2(e.target.files[0]);
	};
	const onChange3 = (e) => {
		setFileName3(e.target.files[0].name);
		setFile3(e.target.files[0]);
	};

	// function sendEmail(e) {
	// 	e.preventDefault();
	// 	if (agentName !== '' || detail !== '') {
	// 		window.confirm('Send an email to all branch managers?') &&
	// 			emailjs.sendForm('bnx_smtp', 'bnxreport', e.target, 'user_oUOcn9RgMAAnjMcwK3Zzp').then(
	// 				(result) => {
	// 					window.alert(result.text, '. Email was sent well');
	// 					props.history.push('/agentlist');
	// 				},
	// 				(error) => {
	// 					setMessage(error.text);
	// 				}
	// 			);
	// 		e.target.reset();
	// 	} else {
	// 		window.alert('Agent name and meeting detail are required');
	// 		setTimeout(() => {
	// 			setMessage('');
	// 		}, 2000);
	// 	}
	// }

	const sendEmail2 = async (e) => {
		e.preventDefault();
		setLoading(true);
		let data = {
			date,
			name,
			aa,
			cod,
			city,
			county,
			agentName: agentName,
			meetingWith,
			specialRequest,
			detail,
			senderEmail,
		};
		try {
			await axios.post(`/api/sendemail`, data).then((res) => {
				let data = res.data.message;
				if (data.success === true) {
					setLoading(false);
					window.alert(data.msgBody);
					props.history.push('/agentlist');
				} else {
					window.alert(data.msgBody);
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleClear2 = () => {
		setFile('');
		setFile2('');
		setFile3('');
		setFileName('upload biz card 1');
		setFileName2('upload biz card 2');
		setFileName3('upload biz card 3');
	};

	// const handleKeyDown = (e) => {
	// 	e.target.style.height = '200px';
	// 	e.target.style.height = `${e.target.scrollHeight}px`;
	// 	In case you have limitation
	// 	e.target.style.height = `${Math.min(e.target.scrollHeight)}px`;
	// };

	return (
		<div>
			<NavBar />
			<div>
				<div className="row" style={{ height: '50px' }}>
					<div className="col" style={{ textAlign: 'center', fontSize: '40px' }}>
						Agent Report
						{loading === true ? <Loading /> : null}
					</div>
				</div>
				<div style={{ padding: 30 }}>
					<form onSubmit={sendEmail2}>
						<div style={{ marginBottom: 20 }}>
							<SaveButton onClick={handleFormSubmit} />
							<SmallClearButton onClick={handleClear} />
							<GoBackButton onClick={() => props.history.push('/agentlist')} />
							{sendEmailButton === true ? (
								<input type="submit" className="btn btn-danger" value="Send email to branch managers" />
							) : null}
						</div>
						<div style={{ alignItem: 'center' }}>
							<div className="form-row align-items-center">
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div>
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Date
											</span>
										</div>
										<input
											type="text"
											name="date"
											value={date}
											onChange={(e) => setDate(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="sample: 20190607"
											readOnly
											style={{ fontColor: 'black' }}
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Writer
											</span>
										</div>
										<input
											type="text"
											name="writer"
											value={name}
											onChange={(e) => setName(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											readOnly
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												A/A
											</span>
										</div>
										<input
											type="text"
											name="aa"
											value={aa}
											onChange={(e) => setAa(e.target.value.toUpperCase())}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="not required"
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												City
											</span>
										</div>
										<input
											type="text"
											name="city"
											value={city}
											onChange={(e) => setCity(e.target.value.toUpperCase())}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="SEL? BSN?"
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												County
											</span>
										</div>
										<input
											type="text"
											name="county"
											value={county}
											onChange={(e) => setCounty(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="강남구? 강북구?"
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												COD
											</span>
										</div>
										<input
											type="text"
											name="cod"
											value={cod}
											onChange={(e) => setCod(e.target.value.toUpperCase())}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="not required"
											style={{ color: 'red' }}
										></input>
									</div>
								</div>

								<input
									type="text"
									name="agentName"
									value={agentName}
									onChange={handleSuggestion}
									className="form-control"
									aria-label="Large"
									aria-describedby="inputGroup-sizing-sm"
									placeholder="Input in English and select from list first. No list? Just type in"
									// minRows={2}
									autoComplete='suggestionAgents'
									list='aaaa'
									hidden='yes'
								>
								</input>
									{/* <datalist id='aaaa'>
									{
										suggestionAgents.map((suggestion, idx) => (
										<option key={idx} onClick={()=> clickSuggestion(suggestion)}>{suggestion}</option>
										))
									}
								</datalist> */}
								
								<div className="col-auto">
									<div className="input-group" style={input2}>
										<div className="input-group-prepend" style={{height:38}}>
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Agent Name
											</span>
										</div>
										<input
											type="text"
											name="agentName"
											value={agentName}
											onChange={handleSuggestion}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											placeholder="Input in English name first (example: Sejung Shipping / 세중해운)"
											// minRows={2}
											autoComplete='suggestionAgents'
											list='aaaa'
										>
										</input>
											<datalist id='aaaa'>
											{
												suggestionAgents.map((suggestion, idx) => (
												<option key={idx} onClick={()=> clickSuggestion(suggestion)}>{suggestion}</option>
												))
											}
										</datalist>
										{/* <Dropdown>
											<DropdownButton title="list">
												{
													suggestionAgents.map((one, idx) => (
														<Dropdown.Item key={idx} onClick={()=> clickSuggestion(one)}>
															{one}
														</Dropdown.Item>
													))
												}
											</DropdownButton>
										</Dropdown> */}
									</div>
								</div>
							</div>
							<div className="form-row align-items-center">
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Visitors
											</span>
										</div>
										<input
											type="text"
											name="visitors"
											value={visitors}
											onChange={(e) => setVisitors(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											// minRows={2}
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Address
											</span>
										</div>
										<input
											type="text"
											name="agentAddress"
											value={agentAddress || ''}
											onChange={(e) => setAgentAddress(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											// minRows={2}
										></input>
									</div>
								</div>
								<div className="col-auto">
									<div className="input-group" style={input1}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												Meeting with
											</span>
										</div>
										<input
											type="text"
											name="meeting with"
											value={meetingWith || ''}
											onChange={(e) => setMeetingWith(e.target.value)}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											// minRows={2}
										></input>
									</div>
								</div>
								{/* <div className="col-auto">
									<div className="input-group" style={{ width: 400 }}>
										<div className="input-group-prepend">
											<span className="input-group-text" style={{ backgroundColor: 'darkslateblue', color: 'white' }}>
												test
											</span>
										</div>
										<input
											type="text"
											name="test"
											value={testAgent || ''}
											onChange={testFunc}
											className="form-control"
											aria-label="Large"
											aria-describedby="inputGroup-sizing-sm"
											// minRows={2}
										></input>
									</div>
									<div>
										{
											// console.log('amen : ', aa)
											suggestionAgents.length > 0 ?
											aa.map((one, i)=>(
												<div> {one} </div>
											)) : null
										}
										test2
									</div>
								</div> */}
							</div>
							<div>{message ? <Message msg={message} /> : null}</div>
							<div className="input-group">
								<TextareaAutosize
									className="form-control"
									aria-label="With textarea"
									type="text"
									name="detail"
									value={detail}
									onChange={(e) => setDetail(e.target.value)}
									style={{ height: '500px' }}
									placeholder="Meeting detail"
									minRows={10}
									// onKeyDown={handleKeyDown}
								/>
							</div>
							<div className="form-row align-items-center">
								<div className="input-group mb-3" style={{ padding: 5 }}>
									<div className="input-group-prepend">
										<span
											className="input-group-text"
											id="inputGroup-sizing-sm"
											style={{ backgroundColor: 'cyan', color: 'red', fontWeight: 'bold' }}
										>
											Special Request
										</span>
									</div>
									<TextareaAutosize
										type="text"
										className="form-control"
										aria-label="Sizing example input"
										aria-describedby="inputGroup-sizing-sm"
										name="special request"
										value={specialRequest || ''}
										onChange={(e) => setSpecialRequest(e.target.value)}
										style={{ color: 'brown', width: '90%' }}
									/>
								</div>
							</div>
						</div>
						{/* <button type="submit" className="btn btn-primary">Send Email</button> */}
					</form>
					<h4 style={{ color: 'red', fontWeight: 'bold' }}>
						***** Upload business cards (.png or .jpg files only & 10 MB size limitation) *****
					</h4>
					<div className="form-row align-items-center" style={{ marginTop: 2, marginBottom: 150 }}>
						<div className="col-auto">
							<form>
								<SmallClearButton onClick={handleClear2} />
								<div className="custom-file" style={{ width: 395 }}>
									<input
										type="file"
										className="custom-file-input"
										id="customFile"
										onChange={onChange}
										style={{ color: 'red' }}
									/>
									<label className="custom-file-label" htmlFor="customFile">
										{fileName}
									</label>
								</div>
							</form>
							{fileAddress ? (
								<div>
									{/* {`${s3Url}${fileAddress}`} */}
									<Image
										src={`${s3Url}${fileAddress}`}
										className="img-thumbnail"
										alt="Responsive image"
										width="550"
										height="292"
									/>
								</div>
							) : null}

							{/* <Image src={bizCard} className="img-thumbnail" alt="Responsive image" width="550" height="292" /> */}
						</div>
						<div className="col-auto">
							{file ? (
								<div>
									<form>
										<SmallClearButton onClick={handleClear2} />
										<div className="custom-file" style={{ width: 395 }}>
											<input type="file" className="custom-file-input" id="customFile" onChange={onChange2} />
											<label className="custom-file-label" htmlFor="customFile">
												{fileName2}
											</label>
										</div>
									</form>
									{fileAddress2 ? (
										<div>
											{/* {`${s3Url}${fileAddress2}`} */}
											<Image
												src={`${s3Url}${fileAddress2}`}
												className="img-thumbnail"
												alt="Responsive image"
												width="550"
												height="292"
											/>
										</div>
									) : null}
								</div>
							) : null}
						</div>
						<div className="col-auto">
							{file2 ? (
								<div>
									<form>
										<SmallClearButton onClick={handleClear2} />
										<div className="custom-file" style={{ width: 395 }}>
											<input type="file" className="custom-file-input" id="customFile" onChange={onChange3} />
											<label className="custom-file-label" htmlFor="customFile">
												{fileName3}
											</label>
										</div>
									</form>
									{fileAddress3 ? (
										<div>
											{/* {`${s3Url}${fileAddress3}`} */}
											<Image
												src={`${s3Url}${fileAddress3}`}
												className="img-thumbnail"
												alt="Responsive image"
												width="550"
												height="292"
											/>
										</div>
									) : null}
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(MeetingReport);
