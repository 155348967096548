import React from 'react';
import { Container, Image } from 'react-bootstrap';
import LogoV3 from '../assets/bnxlogo.png';
import { LoginButton } from '../components/Buttons';
import './NoMatch.css';

export default function NoMatch(props) {
	const clickLogin = () => {
		window.sessionStorage.removeItem('SessionData');
		props.history.push('/');
	};

	return (
		<Container>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 50 }}>
				<Image src={LogoV3} />
			</div>
			<h3
				className="text"
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					marginBottom: 100,
					marginTop: 50,
					color: 'gray',
				}}
			>
				Session was expired. Please login again
			</h3>
			<LoginButton onClick={clickLogin} title="Login" />
		</Container>
	);
}
