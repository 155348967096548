import React from 'react';
import NavBar from '../components/NavBar';
import Notice from '../components/Notice';
import NoticeInput from '../components/NoticeInput';
import CryptoJS from 'crypto-js';
import { SecretCode } from '../config';

export const Home = () => {
	const hashedData = window.sessionStorage.getItem('SessionData') ? window.sessionStorage.getItem('SessionData') : null;
	const bytes = hashedData ? CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`) : null;
	const decryptData = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : null;
	const auth = decryptData ? decryptData.results : null;
	// console.log(decryptData)

	return (
		<>
			<NavBar />
			<div className="container">
				<Notice />
				{auth.roleCode !== 'ADM' ? null : <NoticeInput />}
			</div>
		</>
	);
};
