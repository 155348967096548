import React, { useEffect, memo } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../components/NavBar';
import UserTable from '../components/UserTable';
import { Message } from '../components/Message';
import { token } from '../components/Session';
// import { Proxy } from '../config.js';

const UserList = memo((props) => {
	// const auth = JSON.parse(window.sessionStorage.getItem('user'));
	// const hashedData = window.sessionStorage.getItem('SessionData');
	// const bytes = CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`);
	// const decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	// const auth = decryptData.token;

	const [users, setUsers] = React.useState([]);
	const [message, setMessage] = React.useState('');

	useEffect(() => {
		const getUserList = async () => {
			try {
				await axios
					.get(`/api/users`, {
						headers: {
							Authorization: `bearer ${token}`,
						},
					})
					.then((res) => {
						setUsers(res.data.data);
					});
			} catch (err) {
				if (err.response.status === 500) {
					setMessage('There is server error(front)');
					setTimeout(() => {
						props.history.push('/nomatch');
					}, 2000);
				} else {
					setMessage(err.response.data);
				}
			}
		};
		getUserList();
	}, [props.history]);

	if (users) {
		return (
			<div>
				<NavBar />
				{message ? <Message msg={message} /> : null}
				<UserTable users={users} />
			</div>
		);
	} else {
		return (
			<div>
				<h1>nothing</h1>
			</div>
		);
	}
});

export default withRouter(UserList);
