import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const UnProtectedRoute = ({ component: Component, ...rest }) => {
	// const auth = JSON.parse(window.sessionStorage.getItem('user'));
	// console.log('uunPROTECTEDROUTE AUTH : ', auth);
	// const hashedData = window.sessionStorage.getItem('SessionData');
	const hashedData = window.sessionStorage.getItem('SessionData');
	// const bytes = CryptoJS.AES.decrypt(hashedData.toString(), `${SecretCode}`);
	// const decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	// const auth = decryptData;
	return (
		<Route
			{...rest}
			render={(props) => {
				if (hashedData !== null) {
					return (
						<Redirect
							to={{
								pathname: '/home',
								state: {
									from: props.location,
								},
							}}
						/>
					);
				} else {
					return <Component {...props} />;
				}
			}}
		/>
	);
};
