import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NavBar from '../components/NavBar';
import { Message } from '../components/Message';
import { CloseButton, EditButton, DelButton } from '../components/Buttons';
import { AddSc } from '../components/Modals/AddSc';
import { token, auth } from '../components/Session';
// import { Proxy } from '../config';

const titleStyles = {
	textAlign: 'center',
	color: 'white',
};
const tdStyles = {
	textAlign: 'right',
	paddingTop: 10,
	paddingRight: 20,
};

const modalText = {
	color: 'darkslateblue',
};
const input = {
	width: 320,
};

Modal.setAppElement('#root');

const ServiceContractImport = (props) => {
	const [scDatas, setScDatas] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [editData, setEditData] = useState({});
	// const [newCarrier, setNewCarrier] = useState('');
	const [newScNum, setNewScNum] = useState('');
	const [newEffective, setNewEffective] = useState('');
	const [newEnding, setNewEnding] = useState('');
	const [newRemark, setNewRemark] = useState('');

	const [message, setMessage] = useState('');

	useEffect(() => {
		getScDatas();
		console.log(auth)
	}, []);

	const getScDatas = async () => {
		try {
			await axios.get(`/api/servicecontractimport`).then((res) => {
				if (res.status === 200) {
					const backendScList = res.data;
                    console.log('amen2 : ', backendScList)
					const now = new Date();
					for (let i = 0; i < backendScList.length; i++) {
						const newScList = backendScList.filter(function (newSc) {
							const endingDate = newSc.ending;
							const realEndingDate = new Date(endingDate);
							return now < realEndingDate;
						});
						setScDatas(newScList);
					}
				}
			});
		} catch (err) {
			if (err !== null) {
				console.log('1. err :', err);
				// setMessage(err.response.data.message);
			} else {
				// setMessage(err.response.data.message);
				console.log('2 : ');
			}
		}
	};

	const openModal = (e, scData) => {
		setModalOpen(true);
		setEditData(scData);
	};

	const deleteSc = async (e, scData) => {
		const id = scData.id;
		let config = {
			headers: {
				Authorization: `bearer ${token}`,
			},
		};
		try {
			await axios.delete(`/api/servicecontractimport/${id}`, config).then((res) => {
				if (res.status === 200) {
					setMessage(res.data.message.msgBody);
					window.location.reload();
				}
			});
		} catch (err) {
			setMessage(err);
		}
	};

	const handleEditCheck = () => {
		if ((newScNum === '') & (newEffective === '') && newEnding === '' && newRemark === '') {
			window.alert('nothing to edit');
		} else {
			editSc();
		}
	};

	const editSc = async () => {
		const carrier = editData.carrier;
		const scNum = newScNum ? newScNum : editData.sc_number;
		const effective = newEffective ? moment(newEffective).format('M/D/YYYY') : editData.effective;
		const ending = newEnding ? moment(newEnding).format('M/D/YYYY') : editData.ending;
		const remark = newRemark ? newRemark : editData.remark;
		const id = editData.id;
		// console.log('editdata : ', editData);
		let config = {
			headers: {
				Authorization: `bearer ${token}`,
			},
		};
		let data = { carrier, scNum, effective, ending, remark };
		try {
			await axios.patch(`/api/editsc/${id}`, data, config).then((res) => {
				if (res.status === 200) {
					setMessage(res.data.message.msgBody);
					console.log(res.data);
					setTimeout(() => {
						setModalOpen(false);
						window.location.reload();
					}, 1000);
				}
			});
		} catch (err) {
			console.log(err);
			// setMessage(err);
		}
	};

	// const handleClear = () => {
	// 	setNewScNum(editData.sc_number);
	// 	setNewEffective(editData.effective);
	// 	setNewEnding(editData.ending);
	// 	setNewRemark(editData.remark);
	// };

	const goToScFile = (e, carrierName) => {
		// console.log(carrierName);
		props.history.push(`/totalscfiles/${carrierName}`);
	};


	return (
		<>
			<NavBar />
			<div className="container">
				<h1 style={{ textAlign: 'center', marginTop: 30, marginBottom: 20 }}>Service Contract - Import</h1>
				{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? <AddSc /> : null}
				<table className="table table-sm table-hover table-bordered">
					<thead>
						<tr style={{ backgroundColor: 'brown' }}>
							<th scope="col" style={titleStyles}>
								Carrier
							</th>
							<th scope="col" style={titleStyles}>
								S/C #
							</th>
							<th scope="col" style={titleStyles}>
								Effective
							</th>
							<th scope="col" style={titleStyles}>
								Ending
							</th>
							<th scope="col" style={titleStyles}>
								Remark
							</th>
							{/* <th scope="col" style={titleStyles}>
								Edit
							</th> */}
							{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? (
								<th scope="col" style={titleStyles}>
									Edit
								</th>
							) : null}
							{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? (
								<th scope="col" style={titleStyles}>
									Delete
								</th>
							) : null}
						</tr>
					</thead>
					<tbody>
						{scDatas.length > 0
							? scDatas.map((scData) => (
									<tr key={scData.id}>
										<td
											style={{ color: 'blue', fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}
											onClick={(e) => {
												goToScFile(e, scData.carrier);
											}}
										>
											{scData.carrier}
										</td>
										<td style={{ paddingLeft: 10, paddingTop: 10 }}>{scData.sc_number}</td>
										<td style={tdStyles}>{scData.effective}</td>
										<td style={tdStyles}>{scData.ending}</td>
										<td style={{ paddingLeft: 10, paddingTop: 10 }}>{scData.remark}</td>
										{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? (
											<td style={{ textAlign: 'center' }}>
												<EditButton
													variant="outline-success"
													onClick={(e) => {
														openModal(e, scData);
													}}
												/>
											</td>
										) : null}
										{auth.roleCode === 'ADM' || auth.roleCode === 'ADM2' ? (
											<td style={{ textAlign: 'center' }}>
												<DelButton
													variant="outline-success"
													onClick={(e) => {
														window.confirm('Are you sure???') && deleteSc(e, scData);
													}}
												/>
											</td>
										) : null}
									</tr>
							  ))
							: null}
					</tbody>
				</table>
				{message ? <Message msg={message} /> : null}

				<Modal
					isOpen={modalOpen}
					onRequestClose={() => setModalOpen(false)}
					style={{
						overlay: {
							backgroundColor: 'ghostwhite',
						},
						content: {
							color: 'darkblue',
							backgroundColor: 'beige',
						},
					}}
				>
					<div className="container">
						<div className="col" style={{ marginBottom: 40 }}>
							<h4>Current Service Contract</h4>
							<div style={modalText}>1. Carrier : {editData.carrier}</div>
							<div style={modalText}>2. S/C # : {editData.sc_number}</div>
							<div style={modalText}>3. Effective : {editData.effective}</div>
							<div style={modalText}>4. Ending : {editData.ending}</div>
							<div style={modalText}>5. Remark : {editData.remark}</div>
						</div>
						<form>
							<div className="col" style={{ marginBottom: 20 }}>
								<h4>Edit Service Contract</h4>
								<h6 style={{ color: 'red' }}>Input ONLY what you want to edit and click Edit button</h6>
								<div className="form-row align-items-center">
									<div className="col-auto">
										<input
											type="text"
											className="form-control mb-2"
											placeholder="Carrier"
											value={editData.carrier}
											// onChange={(e) => setNewCarrier(e.target.value)}
											style={input}
											readOnly
										/>
									</div>
									<div className="col-auto">
										<input
											type="text"
											className="form-control mb-2"
											placeholder="S/C #"
											value={newScNum}
											onChange={(e) => setNewScNum(e.target.value)}
											style={input}
										/>
									</div>

									{/* <div className="col-auto">
										<input
											type="text"
											className="form-control mb-2"
											placeholder="Effective"
											value={newEffective}
											onChange={(e) => setNewEffective(e.target.value)}
											style={input}
										/>
									</div> */}
								</div>
								<div className="form-row align-items-center">
									<div className="col-auto">
										<DatePicker
											className="form-control mb-2"
											selected={newEffective}
											onChange={(date) => setNewEffective(date)}
											isClearable
											placeholderText="Effective"
										/>
									</div>
									<div className="col-auto">
										<DatePicker
											className="form-control mb-2"
											selected={newEnding}
											onChange={(date) => setNewEnding(date)}
											isClearable
											placeholderText="Ending"
											// dateFormat="m/ds/yyyy"
											// customInput={{ width: 200 }}
										/>
									</div>
									{/* <div className="col-auto">
										<input
											type="text"
											className="form-control mb-2"
											placeholder="Ending"
											value={newEnding}
											onChange={(e) => setNewEnding(e.target.value)}
											style={input}
										/>
									</div> */}
									<div className="col-auto">
										<input
											type="text"
											className="form-control mb-2"
											placeholder="Remark"
											value={newRemark}
											onChange={(e) => setNewRemark(e.target.value)}
											style={input}
										/>
									</div>
								</div>
								<div>
									<EditButton onClick={() => window.confirm('Are you sure to edit?') && handleEditCheck()} />
									{/* <SmallClearButton onClick={handleClear} /> */}
								</div>
							</div>
						</form>
						{message ? <Message msg={message} /> : null}
						<div style={{ marginTop: 120 }}>
							<CloseButton onClick={() => setModalOpen(false)} />
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default ServiceContractImport;
