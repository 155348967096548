import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './screens/Login';
import { Home } from './screens/Home';
import NoMatch from './screens/NoMatch';
import Register from './screens/Register';
import Profile from './screens/Profile';

import { ProtectedRoute } from './auth/ProtectedRoute';
import { UnProtectedRoute } from './auth/UnProtectedRoute';
import AgentList from './screens/AgentList';
import MeetingReport from './screens/MeetingReport';
import MeetingReportEdit from './screens/MeetingReportEdit';
import UserList from './screens/UserList';
import ServiceContract from './screens/ServiceContract';
import ServiceContractImport from './screens/ServiceContractImport';
import { ScFiles } from './screens/ScFiles';
// import { AplSc } from './screens/ScFiles/AplSc';
// import { CmaSc } from './screens/ScFiles/CmaSc';
// import { OneSc } from './screens/ScFiles/OneSc';
// import { YangMingSc } from './screens/ScFiles/YangMingSc';
// import { CoscoSc } from './screens/ScFiles/CoscoSc';
// import { HmmSc } from './screens/ScFiles/HmmSc';
// import { OoclSc } from './screens/ScFiles/OoclSc';
// import { SmSc } from './screens/ScFiles/SmSc';
import RegisterPublic from './screens/RegisterPublic';
import { TotalScFiles } from './screens/ScFiles/TotalScFiles';

function App() {
	return (
		<Router>
			<Switch>
				<UnProtectedRoute exact path="/" component={Login} />
				<UnProtectedRoute exact path="/registerpublic" component={RegisterPublic} />
				<ProtectedRoute exact path="/home" component={Home} />
				<ProtectedRoute exact path="/agentlist" component={AgentList} />
				<ProtectedRoute exact path="/meetingreport" component={MeetingReport} />
				<ProtectedRoute exact path="/meetingreportedit" component={MeetingReportEdit} />
				<ProtectedRoute exact path="/meetingreportedit/:id" component={MeetingReportEdit} />
				<ProtectedRoute exact path="/profile" component={Profile} />
				<ProtectedRoute exact path="/register" component={Register} />
				<ProtectedRoute exact path="/userlist" component={UserList} />
				<ProtectedRoute exact path="/servicecontract" component={ServiceContract} />
				<ProtectedRoute exact path="/servicecontractimport" component={ServiceContractImport} />
				<ProtectedRoute exact path="/scfiles" component={ScFiles} />
				{/* <ProtectedRoute exact path="/aplsc" component={AplSc} />
				<ProtectedRoute exact path="/cmasc" component={CmaSc} />
				<ProtectedRoute exact path="/onesc" component={OneSc} />
				<ProtectedRoute exact path="/yangmingsc" component={YangMingSc} />
				<ProtectedRoute exact path="/coscosc" component={CoscoSc} />
				<ProtectedRoute exact path="/hmmsc" component={HmmSc} />
				<ProtectedRoute exact path="/ooclsc" component={OoclSc} />
				<ProtectedRoute exact path="/smsc" component={SmSc} /> */}
				<ProtectedRoute exact path="/totalscfiles/:id" component={TotalScFiles} />
				<Route component={NoMatch} />
			</Switch>
		</Router>
	);
}

export default App;
