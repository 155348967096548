import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import 'react-table/react-table.css';
import matchSorter from 'match-sorter';
import NavBar from '../components/NavBar';
import './AgentList.css';
// import { Proxy } from '../config';

const AgentList = (props) => {
	const [posts, setPosts] = useState([]);

	const getList = async () => {
		try {
			await axios.get(`/api/agents`).then((res) => {
				if (res.status === 200) {
					setPosts(res.data);
					// let aa = res.data
					// console.log(aa)
					// let i = 0
					// for (i; aa.length; i++){
					// 	console.log(aa[i].agentName.toUpperCase())
					// 	if (i === 10){
					// 		break
					// 	}
					// }
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getList();
	}, []);

	const onRowClick = (state, rowInfo, column, instance) => {
		return {
			onClick: (e) => {
				let id = rowInfo.original.id;
				props.history.push(`/meetingreportedit/${id}`, id);
				// console.log('A Td Element was clicked!');
				// console.log('it produced this event:', e);
				// console.log('It was in this column:', column);
				// console.log('It was in this row:', rowInfo.original.id);
				// console.log('It was in this table instance:', instance);
				// console.log('state : ', state);
			},
		};
	};

	// function MyCell({
	// 	value,
	// 	columnProps: {
	// 		rest: { someFunc },
	// 	},
	// }) {
	// 	return (
	// 		<a href="#" onClick={someFunc}>
	// 			{value}
	// 		</a>
	// 	);
	// }

	const columns = [
		// {
		// 	Header: 'Email',
		// 	accessor: 'id',
		// 	style: {
		// 		textAlign: 'center',
		// 	},
		// 	width: 50,
		// 	maxWidth: 60,
		// 	minWidth: 60,
		// 	Cell: ({ value }) => <input type="checkbox" />,

		// 	Cell: (cell) => (
		// 		<button value={cell.accessor} onClick={props.handleClickGroup}>
		// 			{cell.accessor}
		// 		</button>
		// 		<InputGroup className="mb-3">
		// 			<InputGroup.Prepend>
		// 				<InputGroup.Checkbox aria-label="Checkbox for following text input" />
		// 			</InputGroup.Prepend>
		// 		</InputGroup>
		// 	),
		// },
		{
			Header: 'Date',
			accessor: 'date',
			style: {
				textAlign: 'center',
				fontSize: '15px',
			},
			width: 80,
			maxWidth: 80,
			minWidth: 80,
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['date'] }),
			filterAll: true,
		},
		{
			Header: 'Name',
			accessor: 'name',
			width: 100,
			maxWidth: 100,
			minWidth: 100,
			multiSort: true,
			// Cell: (row) => <text>name</text>,

			// Cell: onRowClick(),
			// Cell: (getTrProps) => onRowClick2(),
			style: {
				textAlign: 'center',
				fontSize: '15px',
			},
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
			filterAll: true,
		},
		{
			Header: 'A/A',
			accessor: 'AA',
			width: 50,
			maxWidth: 50,
			minWidth: 50,
			multiSort: true,
			// Cell: renderEditable,
			style: {
				textAlign: 'center',
				fontSize: '15px',
				fontWeight: 'bold',
			},
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['AA'] }),
			filterAll: true,
		},
		{
			Header: 'Agent',
			accessor: 'agentName',
			width: 310,
			maxWidth: 310,
			minWidth: 310,
			multiSort: true,
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agentName'] }),
			filterAll: true,
			style: {
				whiteSpace: 'unset',
				color: 'blue',
				fontWeight: 'bold',
				fontSize: '15px',
			},
		},
		{
			Header: 'Visitors',
			accessor: 'visitors',
			width: 150,
			maxWidth: 150,
			minWidth: 150,
			multiSort: true,
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['visitors'] }),
			filterAll: true,
			style: {
				whiteSpace: 'unset',
				color: 'green',
				fontWeight: 'bold',
				fontSize: '15px',
			},
		},
		{
			Header: 'COD',
			accessor: 'COD',
			width: 70,
			maxWidth: 70,
			minWidth: 70,
			multiSort: true,
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['COD'] }),
			filterAll: true,
			style: {
				whiteSpace: 'unset',
				color: 'red',
				fontWeight: 'bold',
				fontSize: '15px',
			},
		},
		{
			Header: 'City',
			accessor: 'city',
			width: 70,
			maxWidth: 70,
			minWidth: 70,
			multiSort: true,
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['city'] }),
			filterAll: true,
			style: {
				whiteSpace: 'unset',
				fontSize: '15px',
				textAlign: 'center',
			},
		},
		{
			Header: 'County',
			accessor: 'county',
			width: 80,
			maxWidth: 80,
			minWidth: 80,
			multiSort: true,
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['county'] }),
			filterAll: true,
			style: {
				whiteSpace: 'unset',
				fontSize: '15px',
				// textAlign: "center",
			},
		},
		{
			Header: 'Detail',
			accessor: 'detail',
			// width: 750,
			// maxWidth: 750,
			// minWidth: 750,
			multiSort: true,
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['detail'] }),
			filterAll: true,
			style: {
				// 'whiteSpace': 'unset',
				fontSize: '14px',
				lineHeight: '25px',
				color: 'black',
			},
		},
		{
			Header: 'Attach',
			accessor: 'empty_bizCard',
			width: 50,
			maxWidth: 50,
			minWidth: 50,
			multiSort: true,
			// Cell: renderEditable,
			style: {
				textAlign: 'center',
				fontSize: '15px',
				fontWeight: 'bold',
				color: 'purple',
			},
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['empty_bizCard'] }),
			filterAll: true,
		},
	];
	return (
		<div>
			<NavBar />
			<h1 style={{ textAlign: 'center', marginTop: 30 }}>Agent List</h1>
			<ReactTable
				columns={columns}
				data={posts}
				filterable
				defaultPageSize={20}
				defaultSorted={[
					{
						id: 'date',
						desc: true,
					},
				]}
				noDataText={'Loading'}
				showPagination={true}
				showPaginationTop={true}
				getTdProps={onRowClick}
			>
				{(state, filterData, instance) => {
					state.pageRows.map((post) => {
						return post._original;
					});
					return (
						<div>
							{filterData()}
							{/* <ExportToExcel posts={ReactTable} /> */}
						</div>
					);
				}}
			</ReactTable>
			<br></br>
			<br></br>

			<br></br>
			<br></br>
		</div>
	);
};

export default AgentList;
